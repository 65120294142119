import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  AreaChartOutlined,
  BellOutlined,
  BookOutlined,
  CaretDownOutlined,
  CarOutlined,
  DashboardOutlined,
  DisconnectOutlined,
  LinkOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Dropdown, Modal, Tooltip, Typography } from "antd";
import LogoInstance from "./shared/attachments/LogoInstance";

import AppBreadcrump from "./AppBreadcrump";
import BaseLayout from "./BaseLayout";
import UserLabel from "./utils/users/UserLabel";

import { signOut } from "../boot/auth";

function AdminLayout({ children, currentUser, modules, ...props }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [guideVisible, setGuideVisible] = useState(false);

  const siderMenuItems = [
    {
      key: "dash",
      icon: <DashboardOutlined />,
      label: <Link to="/admin">{t("components.adminLayout.menu.dashboard")}</Link>,
      title: "Dashboard",
    },
    {
      key: "items",
      icon: <CarOutlined />,
      title: t("components.adminLayout.menu.items.title"),
      label: t("components.adminLayout.menu.items.title"),
      children: [
        {
          key: "items_items",
          label: <Link to="/admin/items">{t("components.adminLayout.menu.items.list")}</Link>,
        },
        {
          key: "items_new_item",
          label: <Link to="/admin/items/new">{t("components.adminLayout.menu.items.add")}</Link>,
        },
        {
          key: "items_groups",
          label: <Link to="/admin/items-groups">{t("components.adminLayout.menu.items.groups")}</Link>,
        },
        ...(modules.geo && currentUser.geo_location_access
          ? [
            {
              key: "items_rt_map",
              label: (
                <Link to="/admin/items/map">
                  {t("components.adminLayout.menu.items.rtmap")}
                </Link>
              ),
            },
          ]
          : []),
        ...(modules.teltonika_dongles
          ? [
            {
              key: "items_trips",
              label: (
                <Link to="/admin/trips">
                  {t("components.adminLayout.menu.items.trips")}
                </Link>
              ),
            },
          ]
          : []),
        ...(!modules.only_telematics && !modules.allow_soft_bookings
          ? [
            {
              key: "return_alerts",
              label: (
                <Link to="/admin/items-return-alerts">
                  {t("components.adminLayout.menu.items.itemReturnAlerts")}
                </Link>
              ),
            },
          ]
          : []),
        ...(modules.teltonika_dongles
          ? [
            {
              key: "item_indicators_alerts",
              label: (
                <Link to="/admin/item-indicators-alerts">
                  {t("components.adminLayout.menu.items.itemIndicatorsAlerts")}
                </Link>
              ),
            },
          ]
          : []),
        ...(modules.bookings && !modules.only_telematics
          ? [
            {
              key: "rolling_laws",
              label: (
                <Link to="/admin/rolling-laws">
                  {t("components.adminLayout.menu.items.rollingLaws")}
                </Link>
              ),
            },
          ]
          : []),
        {
          key: "interventions",
          label: (
            <Link to="/admin/items/interventions">
              {t("components.adminLayout.menu.items.itemInterventions")}
            </Link>
          ),
        },
        {
          key: "incidents",
          label: (
            <Link to="/admin/items/incidents">
              {t("components.adminLayout.menu.items.itemIncidents")}
            </Link>
          ),
        },
      ],
    },
    ...(modules.bookings && !modules.only_telematics
      ? [
        {
          key: "bookings",
          icon: <BookOutlined />,
          label: t("components.adminLayout.menu.bookings.title"),
          title: t("components.adminLayout.menu.bookings.title"),
          children: [
            {
              key: "bookings_bookings",
              label: <Link to="/admin/bookings">{t("components.adminLayout.menu.bookings.list")}</Link>,
            },
            {
              key: "bookings_calendar",
              label: (
                <Link to="/admin/bookings/calendar">
                  {t("components.adminLayout.menu.bookings.calendar")}
                </Link>
              ),
            },
            !modules.disable_no_items_certs && {
              key: "bookings_no_items_certs",
              label: (
                <Link to="/admin/bookings/no-items-certificates">
                  {t("components.adminLayout.menu.bookings.no_items_certs")}
                </Link>
              ),
            },
            {
              key: "booking_restrictions",
              label: (
                <Link to="/admin/bookings/restrictions">
                  {t("components.adminLayout.menu.bookings.restrictions")}
                </Link>
              ),
            },
            {
              key: "booking_validations",
              label: (
                <Link to="/admin/bookings/validations">
                  {t("components.adminLayout.menu.bookings.validations")}
                </Link>
              ),
            },
            ...(modules.booking_projects
              ? [
                {
                  key: "booking_projects",
                  label: (
                    <Link to="/admin/booking-projects">
                      {t("components.adminLayout.menu.bookings.projects")}
                    </Link>
                  ),
                },
              ]
              : []),
          ],
        },
      ]
      : []),

    {
      key: "accesses",
      icon: <UnlockOutlined />,
      title: t("components.adminLayout.menu.accesses.title"),
      label: t("components.adminLayout.menu.accesses.title"),
      children: [
        {
          key: "accesses_accesses",
          label: <Link to="/admin/accesses">{t("components.adminLayout.menu.accesses.list")}</Link>,
        },
        {
          key: "accesses_newAccess",
          label: <Link to="/admin/accesses/new">{t("components.adminLayout.menu.accesses.add")}</Link>,
        },
      ],
    },

    {
      key: "users",
      icon: <UserOutlined />,
      title: t("components.adminLayout.menu.users.title"),
      label: t("components.adminLayout.menu.users.title"),
      children: [
        {
          key: "users_users",
          label: <Link to="/admin/users">{t("components.adminLayout.menu.users.list")}</Link>,
        },
        {
          key: "users_newUser",
          label: <Link to="/admin/users/new">{t("components.adminLayout.menu.users.add")}</Link>,
        },
        {
          key: "users_groups",
          label: <Link to="/admin/users-groups">{t("components.adminLayout.menu.users.groups")}</Link>,
        },
      ],
    },
    {
      key: "itemsuses",
      icon: <AreaChartOutlined />,
      title: t("components.adminLayout.menu.itemsuses.title"),
      label: t("components.adminLayout.menu.itemsuses.title"),
      children: [
        {
          key: "itemsuses_itemsuses",
          label: (
            <Link to="/admin/items-uses">
              {t("components.adminLayout.menu.itemsuses.dashboard")}
            </Link>
          ),
        },
        {
          key: "itemsuses_itemsusesHistory",
          label: (
            <Link to="/admin/items-uses/history">
              {t("components.adminLayout.menu.itemsuses.history")}
            </Link>
          ),
        },
        {
          key: "itemsuses_itemsusesGraph",
          label: (
            <Link to="/admin/items-uses/chronogram">
              {t("components.adminLayout.menu.itemsuses.chronogram")}
            </Link>
          ),
        },
      ],
    },
    {
      key: "settings",
      icon: <SettingOutlined />,
      title: t("components.adminLayout.menu.settings.title"),
      label: t("components.adminLayout.menu.settings.title"),
      children: [
        {
          key: "settings_sites",
          label: <Link to="/admin/sites">{t("components.adminLayout.menu.settings.sites")}</Link>,
        },
        ...(!modules.only_telematics && !modules.allow_soft_bookings && !modules.use_acses_boxs
          ? [
            {
              key: "settings_kboxs",
              label: <Link to="/admin/kboxs">{t("components.adminLayout.menu.settings.kboxs")}</Link>,
            },
          ]
          : []),
        ...(modules.bookings && modules.personal_bookings
          ? [
            {
              key: "settings_pricing-groups",
              label: (
                <Link to="/admin/pricing-groups">
                  {t("components.adminLayout.menu.settings.pricingGroups")}
                </Link>
              ),
            },
          ]
          : []),
        {
          key: "settings_brands",
          label: <Link to="/admin/brands">{t("components.adminLayout.menu.settings.brands")}</Link>,
        },
        {
          key: "settings_colors",
          label: <Link to="/admin/colors">{t("components.adminLayout.menu.settings.colors")}</Link>,
        },
        ...(modules.bookings && !modules.only_telematics
          ? [
            {
              key: "settings_items-ordering",
              label: (
                <Link to="/admin/items-ordering">
                  {t("components.adminLayout.menu.settings.itemsOrdering")}
                </Link>
              ),
            },
          ]
          : []),
        {
          key: "versions",
          label: (
            <Link to="/admin/versions">
              {t("components.adminLayout.menu.settings.updateNotes")}
            </Link>
          ),
        },
      ],
    },
  ];

  const headerContent = (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 16,
        }}
      >
        <LinkOutlined style={{ marginRight: 16 }} />
        <AppBreadcrump />
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <LogoInstance small style={{ marginRight: "1.5rem" }} />

        <Link to="/public">
          <Button danger type="dashed" style={{ marginRight: "16px" }}>
            <LinkOutlined /> {t("components.adminLayout.topBar.userInterface")}
          </Button>
        </Link>

        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "account",
                icon: <UserOutlined />,
                label: t("components.adminLayout.topBar.myAccount"),
                onClick: () => history.push("/admin/account"),
              },
              {
                key: "signOut",
                icon: <DisconnectOutlined />,
                label: t("components.adminLayout.topBar.signOut"),
                onClick: () => signOut().then(() => history.push("/login")),
              },
            ],
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingRight: 16,
              paddingLeft: 16,
              cursor: "pointer",
              borderLeft: "1px solid #DEDEDE",
            }}
          >
            <UserLabel popover={false} user={currentUser} />
            <CaretDownOutlined />
          </div>
        </Dropdown>

        {!modules.use_acses_boxs && (
          <Tooltip title={t("components.adminLayout.topBar.myAuthCode")} placement="bottomLeft">
            <Link
              to="/admin/account?tab=authCode"
              style={{
                borderLeft: "1px solid #DEDEDE",
                height: "100%",
              }}
            >
              <Button type="text" style={{ height: "100%" }}>
                <QrcodeOutlined style={{ fontSize: 20 }} />
              </Button>
            </Link>
          </Tooltip>
        )}

        <Tooltip title={t("components.adminLayout.topBar.myReport")} placement="bottomLeft">
          <Link
            to="/admin/account?tab=reports"
            style={{
              borderLeft: "1px solid #DEDEDE",
              height: "100%",
            }}
          >
            <Button type="text" style={{ height: "100%" }}>
              <BellOutlined style={{ fontSize: 20 }} />
            </Button>
          </Link>
        </Tooltip>

        <Tooltip title="Guide d'utilisation" placement="bottomRight">
          <Button
            onClick={() => setGuideVisible(true)}
            type="text"
            style={{
              borderLeft: "1px solid rgba(0,0,0,0.1)",
              height: "100%",
            }}
          >
            <QuestionCircleOutlined style={{ fontSize: 24 }} />
          </Button>
        </Tooltip>
      </div>
    </>
  );

  const helpModal = (
    <Modal
      key="modal"
      title="Guide d'administration"
      open={guideVisible}
      cancelText="Fermer"
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => setGuideVisible(false)}
    >
      <Card>
        <a
          style={{ fontSize: 18 }}
          href="https://wiki.carpool.keematic.com/admin/home"
          target="_blank"
          rel="noreferrer"
        >
          Lien vers le guide {modules.organisation_name} Carpool
        </a>
      </Card>
      <Card style={{ marginTop: "-4px" }}>
        <p>
          L&apos;accès à la partie admin nécessite une connexion (en haut à droite de la page). Veuillez trouver les
          identifiants ci-dessous :
        </p>
        <p style={{ display: "flex", marginBottom: 0 }}>
          Login:
          <Typography.Paragraph
            style={{ marginLeft: 8 }}
            copyable={{
              text: "admin.carpool@keematic.com",
            }}
          >
            admin.carpool@keematic.com
          </Typography.Paragraph>
        </p>
        <p style={{ display: "flex", marginBottom: 0 }}>
          Mot de passe:
          <Typography.Paragraph
            style={{ marginLeft: 8 }}
            copyable={{
              text: "sgU7wtxXWRCE",
            }}
          >
            sgU7wtxXWRCE
          </Typography.Paragraph>
        </p>
      </Card>
    </Modal>
  );

  return (
    <>
      <BaseLayout
        logoLink="/admin"
        siderMenuItems={siderMenuItems}
        headerContent={headerContent}
        siderMenuTheme="dark"
        {...props}
      >
        {children}
      </BaseLayout>

      {helpModal}
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.account.currentSession,
    modules: state.modules.modules,
  };
}

export default connect(mapStateToProps)(AdminLayout);
